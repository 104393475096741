import {useContext, useEffect, useState} from 'react';
import {SplitContext} from '@splitsoftware/splitio-react';
import {useAppSplitIsInBadState} from '@store/client/store.app';
import {SplitTreatmentValuesEnum, SPLITIO_SPLITS_WITH_CONFIG} from '@integrations/split/split.constants';
import {useQuery} from '@tanstack/react-query';
import {isPlainObjectWithKeys, isValidObjectWithKeys} from '@utils/object';
import { isBrowser } from '@utils/ui';

/**
 * Returns a treatment and it's config.
 *
 * @param {string} splitName - The string that represents the split we want to get the treatment.
 * @param {Attributes=} attributes - No use case yet: An object of type Attributes defining the attributes for the given key.
 * @returns {[string, string | null]} Tuple with treatment first and config second.
 *
 * @remarks
 * split has useTreatment hook, but we can bake in a isReady test here with defaults treatment
 */
type SplitReturn = {
  isReady: boolean;
  isTimedout: boolean;
  isLoaded: boolean;
  isError: boolean;
  treatment: SplitTreatmentValuesEnum | string;
  treatmentConfig: $FixMe;
  isTreatmentOn: boolean;
  isSplitLoaded: boolean;
};

const defaultTreatment = {
  config: null,
  treatment: SplitTreatmentValuesEnum.SEED_CONTROL, // default control treatment
  isReady: false,
  isTimedout: false,
  isLoaded: false,
  isDestroyed: false,
};

const useSplit = (splitName: string, attributes?: $FixMe): SplitReturn => {
  try {
    const {client, isReady, lastUpdate, isTimedout, isDestroyed} = useContext(SplitContext);
    const [{treatment, config: splitConfig}, setTreatment] = useState(defaultTreatment);
    const splitInBadState = useAppSplitIsInBadState();

    /* Config from split is a string. Parse it out */
    const config = !isValidObjectWithKeys(splitConfig) ? JSON.parse(splitConfig!) : splitConfig;
    /* BaseLayoutContent defaults that we set up in constants. Not required. */
    const baseDefaultSplitObject = SPLITIO_SPLITS_WITH_CONFIG[splitName] || {};
    const defaultConfig = baseDefaultSplitObject.config;
    const splitIsReady = Boolean(client && lastUpdate && isReady && !splitInBadState);
    const isValidConfig = isPlainObjectWithKeys(config);
    let treatmentConfig = defaultConfig?.config || {};

    /*
      useQuery
      -This allows us to get the treatment and store it in cache for the duration of the session.
      Please note: We will allow the default stale/cache time. This allows us to keep what we previously
      got but if it gets stale, it will quietly fetch in background if updated.

      We could possibly use refetch in the useEffect.
    */
    const {data: splitQuery} = useQuery({
      retryDelay: 400,
      queryKey: ['split', splitName, lastUpdate],
      queryFn: async () => {
        const splitTreatment = await client?.getTreatmentWithConfig(splitName, attributes);
        /* If we get back _control_, we know we are in a bad state,lets try to again */
        if (!splitTreatment || splitTreatment?.treatment === '_control_') {
          throw Error('Split treatment is _control_');
        }

        if (client) {
          return splitTreatment;
        }

        return SPLITIO_SPLITS_WITH_CONFIG[splitName] || {treatment: SplitTreatmentValuesEnum.OFF, config: null};
      },
      retry: (failureCount, error) => failureCount < 5,
      enabled: splitIsReady && isBrowser(),
    });

    useEffect(() => {
      /* Get update from split, update local state */
      const responseTreatment = splitQuery || defaultTreatment;
      // @ts-ignore
      setTreatment(responseTreatment);
    }, [isReady, splitName, attributes, splitQuery]);

    useEffect(() => {
      if (isTimedout) {
        setTreatment({
          config: treatmentConfig,
          treatment: SplitTreatmentValuesEnum.CONTROL,
          isReady: false,
          isTimedout: true,
          isLoaded: false,
          isDestroyed: false,
        });
      }
    }, [isTimedout]);

    /* There is no split config but there is a default one, return it. */
    if (!config?.id && defaultConfig) {
      treatmentConfig = defaultConfig;
    }

    /* We have a valid config or we have a config but its turrned off "false" */
    if (isValidConfig) {
      if (config?.id && treatment !== SplitTreatmentValuesEnum.OFF) {
        treatmentConfig = config;
      }

      if (!config?.id || treatment === SplitTreatmentValuesEnum.OFF) {
        treatmentConfig = defaultConfig || null;
      }
    }

    const isLoaded = isReady && client && !(isTimedout || isDestroyed);
    const isSplitLoaded = (isTimedout || isLoaded) && treatment !== SplitTreatmentValuesEnum.SEED_CONTROL;
    const isError = (isTimedout || isDestroyed) && !isReady;
    const isTreatmentOn = treatment === SplitTreatmentValuesEnum.ON;

    return <SplitReturn>{treatment, treatmentConfig, isReady, isTimedout, isSplitLoaded, isLoaded, isError, isTreatmentOn};
  } catch(e) {
    return {
      isError: false,
      isLoaded: false,
      isReady: false,
      isSplitLoaded: false,
      isTimedout: false,
      isTreatmentOn: false,
      treatment: '',
      treatmentConfig: undefined
    };
  }


};

export default useSplit;
