/* Utils */
import {checkIsBotUA} from '@utils/ui';

const shouldNotRunCheck = () => {
  const isNotBrowser = !process.browser;
  const isBotUA = checkIsBotUA();
  return isNotBrowser || isBotUA;
};

/*
 * Wrapper methods for Gladly methods.
 * Enables us to error gracefully and format what we need.
 *
 * hideChatIconOverride: function that return a boolean
 * */
type TGladlyChat = {
  isInitialized: boolean;
  start: (options?: {callBack?: () => void; force?: boolean}) => void;
  stop: () => void;
  identify: (_: unknown, data: {email: string}) => void;
  open: () => void;
  logout: () => void;
  hideChat: () => void;
};

const GladlyChat: TGladlyChat = {
  isInitialized: false,

  /**
   * Lets allow for retries if the init fails
   *
   * @param {function} callBack
   * @param {boolean} force - force the initialization
   * @returns {Promise<void>}
   */
  async start({callBack, force = false} = {}) {
    if ((window.Gladly?.getUser || this.isInitialized) && !force) return;

    try {
      GladlyChat.isInitialized = true;
      window.Gladly.init({
        appId: 'hellotech.com',
        autoShowButton: true,
        // eslint-disable-next-line prefer-arrow-callback
      }).then(function () {
        callBack?.();
      });
    } catch (error) {
      this.isInitialized = false;
    }
  },

  stop() {
    window.Gladly?.close();
  },

  identify(_, {email}: {email: string}) {
    if (shouldNotRunCheck()) {
      return;
    }

    window.Gladly?.setUser?.({
      email,
    });
  },

  open() {
    window.Gladly?.show?.();
  },

  logout() {
    window.Gladly?.setUser({
      email: null,
    });
  },

  hideChat() {
    window.Gladly?.close();
  },
};

const HTGladlyChat = new Proxy(GladlyChat, {
  get(target: TGladlyChat, key: keyof TGladlyChat) {
    if (shouldNotRunCheck()) return () => {};

    return target[key];
  },
});

export default HTGladlyChat;
