import {useState, useEffect, useRef} from 'react';
import useInterval from '@hooks/useInterval';
import useSplit from '@integrations/split/useSplit';
import HTGladlyChat from '@integrations/gladly/Gladly/Gladly.methods';
import HTKustomerChatProxy from '@components/Kustomer/Kustomer.methods';

/* Utils */
import {Logger} from '@utils/logger';
import {isBrowser} from '@utils/ui';

export const useWhichChatToShow = () => {
  try {
    const {treatment} = useSplit('showGladlyChat');
    const showGladly = treatment === 'gladly';
    const showKustomer = treatment === 'kustomer';
    const chatIsReady = showKustomer || showGladly || ['Gladly', 'Kustomer'].some(chat => isBrowser() && chat in window);
    // eslint-disable-next-line no-nested-ternary
    const Chat = chatIsReady ? (showGladly ? HTGladlyChat : HTKustomerChatProxy) : undefined;

    return {
      showGladly,
      showKustomer,
      isGladlyChat: showGladly,
      isKustomerChat: showKustomer,
      isChatReady: chatIsReady,
      Chat,
    };
  } catch (e) {
    Logger('Gladly Hooks')(e as Error);
    return {};
  }
};

/*
 * Lets us know when Kustomer Chat is loaded since we are now
 * gonna programmatically launch it. We could also import HtKustomerChat and return the
 * methods when loaded.
 *
 * This is needed because we are not auto loading it.
 * */
export const useGladlyChatLoaded = (start: boolean = false) => {
  const [gladlyLoaded, setGladlyLoaded] = useState(false);
  const gladlyDetectedAt = useRef<number | null>(null);

  const {callbackValue} = useInterval(
    () => {
      if ('Gladly' in window) {
        /* If we haven't stored a timestamp yet, store it and continue checking */
        if (!gladlyDetectedAt.current) {
          gladlyDetectedAt.current = Date.now();
          return false;
        }

        /* Check if 500ms have passed since detection */
        if (Date.now() - gladlyDetectedAt.current >= 300) {
          return true;
        }
      }
      return false;
    },
    start ? 500 : 0,
    start ? 30 : 1,
  );

  useEffect(() => {
    if (callbackValue) {
      setGladlyLoaded(true);
    }
  }, [callbackValue]);

  return {gladlyLoaded};
};
